import React, {useState, useContext, useRef, useEffect} from 'react';
import ChatBody from "../chatBody/chatbody";
import ChatHeader from "../chatHeader/chatheader";
import styles from '../chatBot/chatbot.module.scss';
import { IframeContext } from '../../iFrameContext';
import { Divider } from '@mui/material';
import { useIdleTimer } from 'react-idle-timer'
import { boolean } from 'yup';

interface ChatbotProps{
  deviceType?:boolean;
  toggling?: () => void;
  message?:string;
}

const Chatbot:React.FC<ChatbotProps> = ({deviceType, toggling,message}) =>{
  const [getData,setGetData] = useState<string>("");
  const [chatClose] = useState(true);
  const { iframeData } = useContext(IframeContext);
  const [isChatbotOpen, setIsChatbotOpen] = useState(true);
  const [responseData, setResponseData] = useState(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [agentId, setAgentId] = useState<string>("");
  const [endSession, updateEndSession] = useState <boolean>(false);
  let timeoutResp;
  let timeoutRespPayload;
  const [fulfillmentTexts, setFulfillmentTexts] = useState<
  {
    uid?: number;
    text: string;
    flag: boolean; //simelogo
    isUser: boolean;
    option: any[]; //chipset
    textLength: any; //for showing chipset
   }[]
>  ([
    {
      text: "Hello, I am Comet, a virtual assistant from Simelabs. I can help you get a quick head start for your sales discussion or job search.",
      uid: 0,
      flag: true,
      isUser: false,
      option: [],
      textLength: 1,
    }
   ]);

  const createNewSession = () =>{
    const session_id = Math.random().toString();
    setSessionId(session_id);
    console.log("in parent sessionId",session_id);
  }

  useEffect(() => {
    const handler = (ev: any) => {
      if(typeof ev.data !== "object" && ev.data){
        let receivedData = JSON.parse(ev.data);
        setGetData(receivedData.var1);
      }
      
    }
    window.addEventListener('message', handler)
  
    // Don't forget to remove addEventListener
    return () => window.removeEventListener('message', handler)
  }, []);

  useEffect(()=>{
    if(endSession){
    createNewSession();
    }
    },[endSession])

   const defaultApiOnChatbotOpen = async () => {
    const session_id = Math.random().toString();
    // console.log("session_id", session_id)
    setSessionId(session_id);
    // console.log("in parent sessionId",session_id);
    start();
    const data = {
      agent_id: iframeData?.agentid,
      session_id: session_id,
      text: 'hi',
      timezone: "Asia/Calcutta",
      translate_code: "en",
    };
  
    // console.log("5")
    const response = await fetch(
      process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
 

    let hiResp = await response.json();
    
    let hiRespPayload = hiResp?.payload
    updateEndSession(hiResp?.EndSession?.toLowerCase() === "true"? true : false);


    hiRespPayload.forEach((item:any, index:number) => {
      if(item.fullFillmentText){
        displayText(item.fullFillmentText, index);
      }
      else if(item.richContent){
        let rc:any[] = [];
        item.richContent.forEach((i:any) => {
          i.forEach((e:any) => {
            rc.push(e);
            // console.log("rc",rc);
          })
        });

        rc.forEach((j:any, index:number) => {
          // console.log(j);
          if(j.type === 'chips'){
            displayRichcontent(j.options, "chips", 3);
          }
        })
      }
    });

    if (!response.ok) {
      throw new Error("Request failed with status " + (response.status));
    }
  }

  
// uncomment when releasing to prod
  useEffect(() => {
    // start();
    if(getData==="initial-api" || ""){
      defaultApiOnChatbotOpen();
    }
  }, [getData]);

 
  const onIdle = async () => {
    // console.log("onIdle");
      const data = {
        agent_id:iframeData?.agentid,
        session_id: sessionId,
        text: 'Timeout',
        timezone: "Asia/Calcutta",
        translate_code: "en",
      };

      try{
        // console.log("6")
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (!response.ok) {
          throw new Error("Request failed with status " + response.status);
        }

        timeoutResp = await response.json();
        timeoutRespPayload = timeoutResp?.payload;

        timeoutRespPayload.forEach((item:any, index:number) => {
          if(item.fullFillmentText){
            displayText(item.fullFillmentText, index);
          }
        });

        createNewSession();
        
      }catch (error) {
        console.error("Error:", error);
      }
    // }
  }

  const displayText = (text: any, index:number) => {
    // console.log("Text displayText:", text, index);
      const displayTextValues =[ {
          text:text ,
          flag: index === 0,
          isUser: false,
          option: [],
          textLength: 3
        }]

      
      setFulfillmentTexts((prevFulfillmentTexts:any) => [
        ...prevFulfillmentTexts,
        ...displayTextValues,
      ]);
  }

  const displayRichcontent = (chips:any, type:any, index:number) => {
    const displayRCValues = [{
        text: '',
        type:type,
        flag: index === 0,
        isUser: false,
        option: chips,
        textLength: 3,
        uid:Math.random()
      }];
  
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      ...displayRCValues,     
    ]);
  }
      
  const { start, pause } = useIdleTimer({
    onIdle:!endSession ? onIdle : ()=>{},
    timeout: 180000,
    stopOnIdle:true,
    startManually:true
  })

  const handleClose =() =>{
    setIsChatbotOpen(false);
    toggling && toggling();
  }

  if (!iframeData) {
    return null; // Handle the case when the data is not available yet
  }

  const onSendBtnClick = async (data:any,contentType?:"application/json"|"") => {
    try{
      // console.log(data , "dddwedwe")
      // console.log("outside ",endSession)
      if(endSession){
        console.log(endSession)
        // await createNewSession();
        data.session_id = sessionId;
        // console.log("insideeeeeeeeeeee",data )
      }
      let requestOptions: RequestInit = {
        method: "POST",
      };
  
      if (contentType !== "application/json") {
        const formData = new FormData();
        for (const key in data) {
          
          if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
          }
        }
        requestOptions.body = formData;
      } else {
        requestOptions.headers = {
          "Content-Type": "application/json",
        };
        requestOptions.body = JSON.stringify(data);
        
      }
  
      // console.log("7")
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot", requestOptions);
      setResponseData(await response.json());
      
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div>
      {!deviceType && chatClose && isChatbotOpen &&(
        <div className={styles.chatbotMain}>
          <ChatHeader handleClose={handleClose} setFulfillmentTexts={setFulfillmentTexts} pauseIdleTimer={pause}/>
          <Divider className={styles.dividerCustom}/>   
          <ChatBody responseData={responseData} onResponseData={onSendBtnClick}  fulfillmentTexts={fulfillmentTexts} setFulfillmentTexts={setFulfillmentTexts}  timeoutResponse={timeoutResp}  startIdleTimer={start} sessionId={sessionId} endSession={endSession} updateEndSession={updateEndSession}/>
          {/* <ChatFooter  /> */}
        </div>
      )}


      {(deviceType) && chatClose && isChatbotOpen &&(
       <div className={styles.chatbotMain}>
          <ChatHeader handleClose={handleClose} setFulfillmentTexts={setFulfillmentTexts} pauseIdleTimer={pause}/>
          <Divider className={styles.dividerCustom}/>   
          <ChatBody responseData={responseData} onResponseData={onSendBtnClick} fulfillmentTexts={fulfillmentTexts} setFulfillmentTexts={setFulfillmentTexts}   timeoutResponse={timeoutResp}  startIdleTimer={start} sessionId={sessionId} endSession={endSession} updateEndSession={updateEndSession}/>
       </div>
      )}
    </div>
  )

}

export default Chatbot;